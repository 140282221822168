/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  MdArrowBack,
  MdClearAll,
  MdClose,
  MdDelete,
  MdSave,
} from 'react-icons/md';

import { IoAdd } from 'react-icons/io5';
import { CiReceipt } from 'react-icons/ci';

import Swal from 'sweetalert2';

import withReactContent from 'sweetalert2-react-content';

import { useHotkeys } from 'react-hotkeys-hook';
import { HotKeys, configure } from 'react-hotkeys';

import { Field } from '@atlaskit/form';

import TextField, { TextFieldProps } from '@atlaskit/textfield';
import Toggle from '@atlaskit/toggle';
import Tooltip from '@atlaskit/tooltip';
import {
  ButtonForm,
  Container,
  ContainerLoading,
  Content,
  Footer,
  Header,
  Title,
} from './styles';
import { loadingContext } from '../../context/loading';
import DefaultLoader from '~/components/DefaultLoader';
import { useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { inputsArray, inputsFocos } from './utils';
import { useLinkMaterial } from '~/hooks/useLinkMaterial';

interface CodigoRegistroType {
  value: string | number | undefined;
  des_campo: string;
}

interface FormDefaultProps {
  title?: string;
  subTitle?: string;
  codigoRegistro?: CodigoRegistroType[];
  onSave(data: any): Promise<void>;
  onCancel(): void;
  onClearFields(): void;
  onNew(): void;
  onDelete(): void;
  onReturnSearch(): void;
  onSwitch?(): void;
  getReceipt?(): void;
  isUpdate?: boolean;
  showSwitch?: boolean;
  manterDados?: boolean;
  isDuplicate?: boolean;
  isClear?: boolean;
  isNew?: boolean;
  isDelete?: boolean;
  isSave?: boolean;
  isDeactivateDeleteMessage?: boolean;
  showReceiptButton?: boolean;
  onDuplicate?(): Promise<void>;
  labelButtonTrash?: string;
  deleteMessage?: string;
  codTela?: number;
  id?: string;
  isDisabled?: boolean;
  isDisabledSave?: boolean;
  isCancel?: boolean;
  isBackSearch?: boolean;
  hideFooter?: boolean;
  labelButtonSave?: string;
  showIconSave?: boolean;

  // Custom buttons bar
  customButtonsUpdate?: ReactNode;
  customButtons?: ReactNode;
}

const MySwal = withReactContent(Swal);

const keyMap = {
  hkNewData: 'ctrl+alt+n',
  hkCancelAndDelete: 'ctrl+alt+d',
  hkBackSearch: 'ctrl+alt+r',
  hkClearFields: 'ctrl+alt+l',
  hkSave: 'ctrl+alt+s',
  hkDuplicate: 'ctrl+alt+g',
  hkRecibo: 'ctrl+alt+t',
};

const FormDefault: React.FC<FormDefaultProps> = (props) => {
  const queryClient = useQueryClient();
  const { setLoading, loading } = useContext(loadingContext);
  const {
    title,
    subTitle,
    children,
    onSave,
    onCancel,
    onClearFields,
    onDelete,
    onNew,
    getReceipt,
    onReturnSearch,
    onSwitch = () => null,
    isUpdate,
    showSwitch = false,
    manterDados = false,
    isDuplicate,
    isDelete = true,
    isClear = true,
    isDeactivateDeleteMessage = false,
    isSave,
    onDuplicate,
    labelButtonTrash = 'Excluir',
    deleteMessage = 'Deseja realmente excluir o registro?',
    labelButtonSave = 'Gravar',
    showIconSave = true,
    codTela,
    isNew = true,
    isDisabled = false,
    isDisabledSave = false,
    isCancel = true,
    id,
    codigoRegistro = undefined,
    isBackSearch = true,
    hideFooter = false,
    showReceiptButton = false,
    customButtons,
    customButtonsUpdate,
  } = props;
  const [linkMaterial, setLinkMaterial] = useState('');
  const [codTelaOnsave, setCodTelaOnsave] = useState<number>(0);

  const formRef = useRef<any>(null);

  const { setValue, getValues } = useForm();
  const { fetchLinkMaterial } = useLinkMaterial();

  useEffect(() => {
    setValue('formDefaultIsUpdate', isUpdate);
  }, [isUpdate]);

  configure({
    ignoreTags: ['input', 'select', 'textarea'],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ignoreEventsCondition(keyEvent: KeyboardEvent) {
      return false;
    },
  });

  const handleDelete = () => {
    if (!isDeactivateDeleteMessage) {
      MySwal.fire({
        title: ``,
        text: deleteMessage,

        showCancelButton: true,
        confirmButtonColor: '#07289e',
        cancelButtonColor: '#ff7b7b',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if (result.isConfirmed) {
          onDelete();
        }
      });
    } else {
      onDelete();
    }
  };

  const handleCancel = () => {
    MySwal.fire({
      title: ``,
      text: 'Deseja Realmente Cancelar? ',

      showCancelButton: true,
      confirmButtonColor: '#07289e',
      cancelButtonColor: '#ff7b7b',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        onCancel();
      }
    });
  };

  const validaTelaAtalho = () => {
    const cod_tela_active: any = queryClient.getQueryData(`cod_tela_active`);
    if (cod_tela_active) {
      if (cod_tela_active.cod_tela === codTela) {
        return true;
      }
    }

    return false;
  };

  useHotkeys(
    'ctrl+alt+n',
    () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        if (isNew) {
          onNew();
        }
      }
    },
    [],
  );
  // Gravar
  useHotkeys(
    'ctrl+alt+s',
    () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        if (!isSave) {
          handleOnSave({});
        }
      }
    },
    [],
  );

  // Limpar
  useHotkeys(
    'ctrl+alt+l',
    () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        const formDefaultIsUpdate = getValues('formDefaultIsUpdate');
        if (!formDefaultIsUpdate && isClear) {
          onClearFields();
          return false;
        }
      }
    },
    [],
  );
  // voltar
  useHotkeys(
    'ctrl+alt+r',
    () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        const formDefaultIsUpdate = getValues('formDefaultIsUpdate');
        if (formDefaultIsUpdate && isBackSearch) {
          onReturnSearch();
          return false;
        }
      }
    },
    [],
  );
  // Cancelar | Excluir
  useHotkeys(
    'ctrl+alt+d',
    () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        const formDefaultIsUpdate = getValues('formDefaultIsUpdate');
        if (isCancel && !formDefaultIsUpdate) {
          handleCancel();
          return false;
        }

        if (formDefaultIsUpdate && isDelete) {
          handleDelete();
          return false;
        }
      }
    },
    [],
  );
  // gravar e duplicar
  useHotkeys(
    'ctrl+alt+g',
    () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        if (onDuplicate) {
          onDuplicate();
        }
      }
    },
    [],
  );
  // Recibo
  useHotkeys(
    'ctrl+alt+t',
    () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        if (getReceipt) {
          getReceipt();
        }
      }
    },
    [],
  );
  const handleOnSave = useCallback(
    async (e: any) => {
      try {
        setLoading(true);
        if (codTela) {
          setCodTelaOnsave(codTela);
        }
        await onSave(e);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, codTela, onSave],
  );
  const handleOnDuplicate = useCallback(async () => {
    try {
      setLoading(true);
      if (onDuplicate) await onDuplicate();
    } finally {
      setLoading(false);
    }
  }, [setLoading, onDuplicate]);

  const formSubmit = useCallback(
    async (e) => {
      await handleOnSave(e);
    },
    [handleOnSave],
  );

  const keyHandlers = {
    hkCancelAndDelete: () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        const formDefaultIsUpdate = getValues('formDefaultIsUpdate');
        if (isCancel && !formDefaultIsUpdate) {
          handleCancel();
          return false;
        }

        if (formDefaultIsUpdate && isDelete) {
          handleDelete();
          return false;
        }
      }
    },
    hkNewData: () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        if (isNew) {
          onNew();
        }
      }
      return false;
    },
    hkBackSearch: () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        const formDefaultIsUpdate = getValues('formDefaultIsUpdate');
        if (formDefaultIsUpdate && isBackSearch) {
          onReturnSearch();
        }
      }
      return false;
    },
    hkClearFields: () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        const formDefaultIsUpdate = getValues('formDefaultIsUpdate');
        if (!formDefaultIsUpdate && isClear) {
          onClearFields();
        }
      }
      return false;
    },
    hkSave: async (e: any) => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        if (!isSave) {
          await handleOnSave(e);
        }
      }
      return false;
    },
    hkDuplicate: async () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        if (onDuplicate) {
          await onDuplicate();
        }
      }
      return false;
    },
    hkRecibo: () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        if (getReceipt) {
          getReceipt();
        }
      }

      return false;
    },
  };

  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (divRef.current) divRef.current.focus();
    // myRef.current.focus();
  }, []);

  useEffect(() => {
    (async () => {
      if (codTela) {
        const link = await fetchLinkMaterial(codTela);
        setLinkMaterial(link);
      } else setLinkMaterial('');
    })();
  }, [codTela, fetchLinkMaterial]);

  const onFirstFocus = useCallback(() => {
    if (isUpdate) return;

    setTimeout(() => {
      if (codTela) {
        const activeTab = document.getElementById(`${codTela}-tab`);

        if (!activeTab) return;

        const { id: idActiveTab, ariaSelected: ariaSelectedTab } = activeTab;

        const inputArray = inputsArray({
          idActiveTab,
          ariaSelectedTab,
          codTela,
        });

        if (inputArray.length > 0) inputsFocos(inputArray, codTela);
      }
    }, 100);
  }, [codTela, isUpdate]);

  useEffect(() => {
    if (codTela && !isUpdate) {
      const activeTab = document.getElementById(`${codTela}-tab`);

      if (activeTab) {
        setTimeout(
          () => {
            const { id: idActiveTab, ariaSelected: ariaSelectedTab } =
              activeTab;

            const inputArray = inputsArray({
              idActiveTab,
              ariaSelectedTab,
              codTela,
            });

            if (inputArray.length > 0) inputsFocos(inputArray, codTela);
          },
          codTela === 41 ? 0 : 600,
        );
      }
    }
  }, [codTela, isUpdate, loading]);

  const returnCodTelaActive = () => {
    const telaAtiva: any = queryClient.getQueryData(`cod_tela_active`);

    if (telaAtiva) {
      return telaAtiva.cod_tela;
    }

    return null;
  };

  if (loading && returnCodTelaActive() === codTelaOnsave) {
    return (
      <ContainerLoading>
        <DefaultLoader />
      </ContainerLoading>
    );
  }

  return (
    <HotKeys id="hotkeys" keyMap={keyMap} handlers={keyHandlers} tabIndex={-1}>
      <Container ref={divRef} tabIndex={0}>
        {isUpdate}

        {!isUpdate && (
          <Header>
            <Title>
              <h3>{title}</h3>
              {subTitle && <h6>{subTitle}</h6>}
            </Title>
          </Header>
        )}
        {isUpdate && (
          <Header>
            <Title>
              <h3>
                {title}
                {codigoRegistro && (
                  <small
                    style={{
                      fontSize: '0.60em',
                      color: '#7c7c7c',
                      marginLeft: '10px',
                    }}
                  >
                    {`(${codigoRegistro
                      .map(
                        (registro) =>
                          `${registro.des_campo}: ${registro.value}`,
                      )
                      .join(', ')})`}
                    {/* {`(${codigoRegistro[0].des_campo}: ${codigoRegistro[0].value})`} */}
                  </small>
                )}
              </h3>
              {subTitle && <h6>{subTitle}</h6>}
            </Title>

            {isBackSearch && (
              <Tooltip position="bottom" content="CTRL + ALT + R">
                <ButtonForm
                  onClick={() => {
                    onReturnSearch();
                  }}
                  disabled={isDisabled}
                  className={
                    isDisabled ? 'return-search disabled' : 'return-search'
                  }
                >
                  <MdArrowBack /> Voltar para pesquisa
                </ButtonForm>
              </Tooltip>
            )}
          </Header>
        )}
        <Content onSubmit={formSubmit}>
          {({ formProps }) => (
            <form {...formProps} ref={formRef} id={id}>
              {children}
            </form>
          )}
        </Content>

        {!hideFooter && (
          <Footer>
            {/* Modo inserção */}
            {!isUpdate && (
              <div className="containerButtons">
                {linkMaterial ? (
                  <div className="containerMaterial">
                    <a
                      href={linkMaterial}
                      target="_blank"
                      className="linkMaterial"
                    >
                      Precisa de ajuda?
                    </a>
                  </div>
                ) : (
                  <div style={{ display: 'hidden' }} />
                )}
                <div className="right">
                  {/* BARRA DE BOTÕES CUSTOMIZÁVEIS */}
                  {customButtons}

                  {showSwitch && (
                    <div className="left">
                      Manter dados na tela?
                      <Toggle
                        id="toggle-default"
                        isChecked={manterDados}
                        onChange={() => onSwitch()}
                      />
                    </div>
                  )}

                  {/* Botão de gravar */}
                  {!isSave && (
                    <Tooltip position="bottom" content="CTRL + ALT + S">
                      <ButtonForm
                        disabled={isDisabled || isDisabledSave || loading}
                        onClick={async (e) => {
                          await handleOnSave(e);
                        }}
                        className={
                          isDisabled || isDisabledSave || loading
                            ? 'gravar disabled'
                            : 'gravar'
                        }
                      >
                        <MdSave /> {labelButtonSave}
                      </ButtonForm>
                    </Tooltip>
                  )}
                  {isDuplicate && (
                    <Tooltip position="bottom" content="CTRL + ALT + G">
                      <ButtonForm
                        disabled={loading}
                        className={loading ? 'duplicate disabled' : 'duplicate'}
                        onClick={async () => {
                          if (onDuplicate) await handleOnDuplicate();
                        }}
                      >
                        <MdSave /> Gravar e Duplicar
                      </ButtonForm>
                    </Tooltip>
                  )}
                  {isClear && (
                    <Tooltip position="bottom" content="CTRL + ALT + L">
                      <ButtonForm
                        disabled={isDisabled}
                        onClick={() => {
                          onClearFields();
                          onFirstFocus();
                        }}
                        className={isDisabled ? 'limpar disabled' : 'limpar'}
                      >
                        <MdClearAll /> Limpar Campos
                      </ButtonForm>
                    </Tooltip>
                  )}
                  {isCancel && (
                    <Tooltip position="bottom" content="CTRL + ALT + D">
                      <ButtonForm
                        disabled={isDisabled}
                        onClick={handleCancel}
                        className={
                          isDisabled ? 'cancelar disabled' : 'cancelar'
                        }
                      >
                        <MdClose /> Cancelar
                      </ButtonForm>
                    </Tooltip>
                  )}
                </div>
              </div>
            )}

            {isUpdate && (
              <div className="containerButtons">
                {linkMaterial ? (
                  <div className="containerMaterial">
                    <a
                      href={linkMaterial}
                      target="_blank"
                      className="linkMaterial"
                    >
                      Precisa de ajuda?
                    </a>
                  </div>
                ) : (
                  <div style={{ display: 'hidden' }} />
                )}
                <div className="right">
                  {/* BARRA DE BOTÕES CUSTOMIZÁVEIS COM STATUS DE UPDATE */}
                  {customButtonsUpdate}

                  {showReceiptButton && (
                    <Tooltip position="bottom" content="CTRL + ALT + T">
                      <ButtonForm
                        disabled={false}
                        onClick={getReceipt}
                        className="receipt"
                      >
                        <CiReceipt color="#fff" /> Recibo
                      </ButtonForm>
                    </Tooltip>
                  )}
                  {isNew && (
                    <Tooltip position="bottom" content="CTRL + ALT + N">
                      <ButtonForm
                        disabled={isDisabled}
                        onClick={() => {
                          onNew();
                          onFirstFocus();
                        }}
                        className={isDisabled ? 'novo disabled' : 'novo'}
                      >
                        <IoAdd color="#fff" /> Novo
                      </ButtonForm>
                    </Tooltip>
                  )}

                  {/* Botão de gravar */}
                  {!isSave && (
                    <Tooltip position="bottom" content="CTRL + ALT + S">
                      <ButtonForm
                        disabled={isDisabled || isDisabledSave || loading}
                        className={
                          isDisabled || isDisabledSave || loading
                            ? 'gravar disabled'
                            : 'gravar'
                        }
                        onClick={async (e) => {
                          await handleOnSave(e);
                        }}
                      >
                        {showIconSave ? <MdSave /> : ''}
                        {labelButtonSave}
                      </ButtonForm>
                    </Tooltip>
                  )}
                  {isDelete && (
                    <Tooltip position="bottom" content="CTRL + ALT + D">
                      <ButtonForm
                        disabled={isDisabled}
                        className={isDisabled ? 'delete disabled' : 'delete'}
                        onClick={handleDelete}
                      >
                        <MdDelete /> {labelButtonTrash}
                      </ButtonForm>
                    </Tooltip>
                  )}
                  {isDuplicate && (
                    <Tooltip position="bottom" content="CTRL + ALT + G">
                      <ButtonForm
                        disabled={loading}
                        className={loading ? 'duplicate disabled' : 'duplicate'}
                        onClick={async () => {
                          if (onDuplicate) await handleOnDuplicate();
                        }}
                      >
                        <MdSave /> Gravar e Duplicar
                      </ButtonForm>
                    </Tooltip>
                  )}
                </div>
              </div>
            )}
          </Footer>
        )}
      </Container>
    </HotKeys>
  );
};

export default FormDefault;

interface InputUpperProps extends TextFieldProps {
  label: string;
  name: string;
  onChangeText(value: string): void;
}

export const InputUpper: React.FC<InputUpperProps> = ({
  label,
  name,
  onChangeText,
  ...props
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    onChangeText(value.toUpperCase());
  };
  return (
    <Field label={label} name={name}>
      {({ fieldProps }) => (
        <>
          <TextField {...fieldProps} {...props} onChange={handleChange} />
        </>
      )}
    </Field>
  );
};
