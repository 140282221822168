/* eslint-disable prefer-destructuring */
interface inputArrayProps {
  idActiveTab: string;
  ariaSelectedTab: string | null;
  codTela: number;
}

const codTelasEspeciais = {
  focoNoZero: [41, 23],
  focoNoUm: [],
  lancamentoOutrasOp: [172, 203],
  placeholderPreenchidos: [284, 44],
};

const getInputElement = (
  item: Element | HTMLDivElement,
): NodeListOf<HTMLInputElement> => {
  return item.querySelectorAll(
    'input[type="text"], input[type="number"], input[type="date"]',
  );
};

const removeSpecialCharacters = (str: string): string => {
  return str.replace(/[^a-zA-Z0-9\s]/g, '');
};

const moveCursorToStart = (inputElement: HTMLInputElement) => {
  if (inputElement.type === 'number' || inputElement.type === 'date') {
    inputElement.value = '';
    return;
  }
  inputElement.selectionStart = 0;
  inputElement.selectionEnd = 0;
  inputElement.value = '';
};

const inputFocused = (name: string, id: string): HTMLInputElement | null => {
  const attr = name === '' ? 'id' : 'name';
  const val = name === '' ? id : name;

  const inputFocus: HTMLInputElement | null = document.querySelector(
    `[${attr}="${val}"]`,
  );

  return inputFocus;
};

const findInputsByNames = (
  inputArray: { name: string; id: string }[],
): HTMLInputElement[] => {
  const inputs: HTMLInputElement[] = [];

  inputArray.forEach((inputData) => {
    const { name, id } = inputData;

    if (name.trim() !== '' || id.trim() !== '') {
      const selector =
        name.trim() !== '' ? `input[name='${name}']` : `input[id='${id}']`;

      const foundInputs = document.querySelectorAll(
        selector,
      ) as NodeListOf<HTMLInputElement>;

      foundInputs.forEach((input) => {
        if (
          input.type === 'number' ||
          input.type === 'text' ||
          input.type === 'date'
        ) {
          let label: HTMLElement | null = input;

          let count = 0;
          while (
            label &&
            label.tagName.toLowerCase() !== 'label' &&
            count < 7
          ) {
            label = label.parentElement;
            count++;
          }
          if (label && !label.textContent?.toLowerCase().includes('loja')) {
            inputs.push(input);
          }
        }
      });
    }
  });

  return inputs;
};

export const inputsArray = ({
  idActiveTab,
  ariaSelectedTab,
  codTela,
}: inputArrayProps): {
  name: string;
  id: string;
}[] => {
  const inputArray: { name: string; id: string }[] = [];

  if (ariaSelectedTab === 'true') {
    const idTab = parseInt(idActiveTab.split('-')[0], 10);

    if (idTab === codTela) {
      const form = document.getElementsByClassName('tab-pane');

      Array.from(form).forEach((item) => {
        if (item.role === 'tabpanel' && parseInt(item.id, 10) === codTela) {
          const inputs = getInputElement(item);

          const selectFields = item.getElementsByClassName('select--field');

          if (
            selectFields.length <= 0 ||
            (inputs.length > 0 && selectFields.length > 0)
          ) {
            inputArray.push(
              ...Array.from(inputs)
                .filter(
                  (input) =>
                    !input.disabled &&
                    !input.readOnly &&
                    (input.name.trim() !== '' || input.id.trim() !== ''),
                )
                .map((input) => ({ name: input.name, id: input.id })),
            );
          }
        }
      });
    }
  }

  return inputArray;
};

export const inputsFocos = (
  inputArray: {
    name: string;
    id: string;
  }[],
  codTela: number,
): void => {
  if (
    inputArray.length > 0 &&
    (inputArray[0].id.trim().includes('limit-pagination') ||
      inputArray[0].name.trim().includes('limit-pagination'))
  )
    return;

  if ([30, 196, 293].includes(codTela)) return;

  if (findInputsByNames(inputArray).length > 0 && [40].includes(codTela)) {
    const input = findInputsByNames(inputArray)[0];

    const elementosPais: HTMLElement[] = [];
    let pai = input.parentElement;

    while (pai !== null && elementosPais.length < 5) {
      elementosPais.push(pai);
      pai = pai.parentElement;
    }

    if (
      elementosPais[3].textContent &&
      elementosPais[3].textContent.trim().toLowerCase().includes('selecione')
    ) {
      input.focus();
      input.click();
      moveCursorToStart(input);
    }
    return;
  }

  const filteredArray =
    codTela === 3
      ? inputArray
      : inputArray.filter(
          (item) =>
            !(
              item.name.toLowerCase().includes('loja') ||
              item.id.toLowerCase().includes('loja')
            ),
        );

  if (codTelasEspeciais.placeholderPreenchidos.includes(codTela)) {
    const { name, id } = filteredArray[0];
    const inputFocus = inputFocused(name, id);

    if (inputFocus) {
      const { placeholder } = inputFocus;

      if (!placeholder.trim().toLowerCase().includes('selecione')) return;
    }
  }

  if ([49, 11, 51, 46, 26].includes(codTela)) {
    const { name, id } = filteredArray[0];
    const inputFocus = inputFocused(name, id);

    if (inputFocus) {
      const elementosPais: HTMLElement[] = [];
      let pai = inputFocus.parentElement;

      while (pai !== null && elementosPais.length < 5) {
        elementosPais.push(pai);
        pai = pai.parentElement;
      }

      if (elementosPais[3].textContent) {
        if (
          elementosPais[3].textContent
            .trim()
            .toLowerCase()
            .includes('selecione')
        ) {
          inputFocus.focus();
          inputFocus.click();
          moveCursorToStart(inputFocus);
        } else return;
      }
    }
    return;
  }

  const inputs = [];

  const inputSelected = filteredArray[0];

  if (!inputSelected) return;

  const { name, id } = inputSelected;

  const inputFocus = inputFocused(name, id);

  if (inputFocus) {
    const { value, placeholder, name: nameFocused, defaultValue } = inputFocus;

    if (
      nameFocused === 'busca_parceiro' &&
      placeholder.trim() !== 'Selecione...'
    )
      return;

    if (codTela === 41 && defaultValue !== '') {
      inputFocus.blur();
      return;
    }

    const strValue = removeSpecialCharacters(value);
    const strPlaceholder = removeSpecialCharacters(placeholder);

    if (codTelasEspeciais.focoNoZero.includes(codTela)) {
      inputs.push(inputFocus);
    } else if (value.includes('___.')) {
      inputs.push(inputFocus);
    } else if (
      (strValue.length <= 0 || strValue === '') &&
      strPlaceholder.length >= 1 &&
      strPlaceholder.length <= 30
    ) {
      inputs.push(inputFocus);
    } else if (
      (strValue.length <= 0 || strValue === '') &&
      (strPlaceholder.length <= 0 || strPlaceholder === '')
    ) {
      inputs.push(inputFocus);
    }
  }

  if (inputs.length > 0) {
    if (codTelasEspeciais.focoNoZero.includes(codTela)) {
      inputs[0].focus();
      inputs[0].click();
      moveCursorToStart(inputs[0]);
    } else {
      inputs[0].focus();
      inputs[0].click();
      moveCursorToStart(inputs[0]);
    }
  }
};
